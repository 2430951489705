import React from 'react'
import { Link } from 'gatsby'

import rAndDImg from '../../images/svg/pricing/r-and-d.svg'
import fundingImg from '../../images/svg/pricing/funding.svg'
import taxInvestigationImg from '../../images/svg/pricing/tax-investigation.svg'

import SiteLayout from '../../layout/SiteLayout'
import SEO from '../../components/SEO'
import Section from '../../components/Section'
import { Title, TitleContainer, TitleIntro } from '../../components/Title'
import { CtaCurve, CtaCurveTitle } from '../../components/CtaCurve'
import {
  SideBySide,
  SideBySideImg,
  SideBySideText,
  SideBySideTitle,
} from '../../components/SideBySide'

const SpecialisedServices = () => {
  return (
    <SiteLayout header="light">
      <SEO title="Pricing" />
      <TitleContainer>
        <Title>Specialised services</Title>
        <TitleIntro>
          Our specialised services are carried out by experts with a track
          record of success. From helping you reclaim tax you didn't know you
          could to securing funding that takes your business to the next level,
          we're more than your normal accounting firm.
        </TitleIntro>
      </TitleContainer>
      <Section>
        <SideBySide>
          <SideBySideImg src={rAndDImg} alt="R&D Tax Credits" />
          <SideBySideText>
            <SideBySideTitle>
              Research & Development (R&D) Tax Credits
            </SideBySideTitle>
            <p>
              We can assess, prepare and submit your R&D Tax Credit claim so you
              can deduct a massive 230% of your costs against your profits,
              resulting in huge Corporation Tax savings. If you're just starting
              out and don't have any profits we can even get you a significant
              tax refund - even if your business has never paid tax!
            </p>
            <p>Our fees are typically 10-20% of the tax saved.</p>
            <strong>Some typical sectors: </strong>
            <ul className="bullet-list">
              <li>Software development</li>
              <li>Manufacturing</li>
              <li>Medicine</li>
            </ul>
          </SideBySideText>
        </SideBySide>
        <SideBySide reverse>
          <SideBySideText>
            <SideBySideTitle>Fundraising</SideBySideTitle>
            <p>
              By utilising our partner network we can raise funds for a number
              of different purposes. How we charge our fees depend on the nature
              of the work carried out, the purpose of the loan and the end
              invester or lender.
            </p>
            <strong>Funding purposes we support: </strong>
            <ul className="bullet-list">
              <li>Buying another business</li>
              <li>Growing your business (i.e. taking on more staff)</li>
              <li>Restructuring debts</li>
              <li>Invoice discounting or factoring</li>
            </ul>
          </SideBySideText>
          <SideBySideImg src={fundingImg} alt="Fundraising" />
        </SideBySide>
        <SideBySide>
          <SideBySideImg src={taxInvestigationImg} alt="Tax investigations" />
          <SideBySideText>
            <SideBySideTitle>Tax investigation support</SideBySideTitle>
            <p>
              Our tax team can take full control of a tax investigation to
              ensure your rights are upheld and to minimise the risk of
              penalties and unexpected tax bills. Our fees depend on the scope
              of work involved but we would usually require a payment on account
              to become formally appointed as your tax agents.
            </p>
            <strong>Cases we support: </strong>
            <ul className="bullet-list">
              <li>Personal tax investigations</li>
              <li>Corporation tax investigations</li>
              <li>VAT investigations</li>
              <li>PAYE investigations</li>
            </ul>
          </SideBySideText>
        </SideBySide>
      </Section>
      <CtaCurve white>
        <CtaCurveTitle>Need to speak to an expert?</CtaCurveTitle>
        <p>We can call you anytime between 9am - 5pm Monday to Friday.</p>
        <Link className="btn btn-primary-invert" to="/call-back">
          Request a call back
        </Link>
      </CtaCurve>
    </SiteLayout>
  )
}

export default SpecialisedServices
